import React from 'react'
import Grid from '@mui/material/Grid';
import makuniImage from '../images/makuniImage.jpg';
import makuniImage2 from '../images/makuniImage2.jpg';

function Makuni() {
    return (
        <Grid container spacing={2} alignItems="center"
        justifyContent="center">
        <Grid item xs={8}>
        <h1>Call for order at @9110112711</h1>
        
        <img src={makuniImage2} width="500" height="400" />
        <img src={makuniImage} width="500" height="400" />
       
        </Grid>

        </Grid>
    )
}

export default Makuni