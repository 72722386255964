import Typography from '@mui/material/Typography';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import makuni from '../images/makuni.png';
import makuniHome from '../images/makuniHome.jpg';
import coconut from '../images/coconut.jpg'; 

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  align: 'center'
}));

function Home() {

  const mystyle = {
    position: 'relative'
}
  return (
    <div style={mystyle}>
      <Box sx={{ flexGrow: 1, alignSelf: 'center' }}>

        <Item> <h1>The Royal Taste of Bihar</h1></Item>

      </Box>

      <Grid container spacing={2} alignItems="center"
        justifyContent="center">
        <Grid item xs={8}>
        <div>
            <h2> We are providing very good taste with pure (100%) quality food at cost-effective rate. </h2>
            <h2>Accepting order online/offline.  </h2>
            <div>
              <img src={makuniHome} width="700" height="400" />
            </div>

          </div>
        </Grid>

        <Grid item xs={8}>
        <div>
            <h2> Our Product : </h2>

            <h3>Makuni (Litti Chokha)</h3>
            <div>
              <img src={makuni} width="300" height="200" />
            </div>

            <h3>Coconut water</h3>
            <div>
              <img src={coconut} width="300" height="200" />
              Image by <a href="https://www.freepik.com/free-vector/hand-drawn-coconut-cocktail-background_4503226.htm#query=coconut%20water&position=8&from_view=search&track=ais">Freepik</a>
            </div>

          </div>
        </Grid>
      </Grid>

    </div>


  );
}

export default Home;