import React from 'react'
import Typography from '@mui/material/Typography';
import NavTabs from '../DrawerAppBar'

function Footer() {

      const mystyle = {
        position: 'fixed',
        bottom: "0px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }

    return (
        <div style={mystyle}>
               
            <Typography>Copyright @BunnuProduct 2023</Typography>
        </div>
    )
}

export default Footer