import React from 'react'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

function About() {
    return (
        <Grid container spacing={2} alignItems="center"
        justifyContent="center">
        <Grid item xs={8}>
        <h2>Our vision : </h2>
        <Typography>1. Provding very good quality of food.</Typography>
        <Typography>2.Provding delicious and hygiene food. </Typography>
        <Typography>3.Fresh and fast delivery. </Typography>
        </Grid>

        </Grid>
    )
}

export default About