import React from 'react'
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';

function Contact() {
    return (
        <Grid container spacing={2} alignItems="center"
        justifyContent="center">
        <Grid item xs={8}>
        <h3>Address : </h3><Typography>Purnendu Nagar, Anisabad , Patna</Typography>
        <h3>Contact Number :</h3><Typography> 9110112711</Typography>
        </Grid>

        </Grid>
    )
}

export default Contact