import { Routes, Route } from "react-router-dom"
import Home from './pages/Home'
import About from "./pages/About"
import Contact from "./pages/Contact"
import Product from "./pages/Product"
import Footer from "./pages/Footer";
import Makuni from "./pages/Makuni";
import NavTabs from "./DrawerAppBar"
import Navbar from "./component/Navbar"
import { Outlet } from 'react-router-dom'

function BasicLayout() {
  return (
    <>
      <Navbar/>
      <Outlet />
      <Footer/>
    </>
  )
}

function App() {
  return (
    <div className="App">
      <Routes>
      <Route path="/" element={<BasicLayout />}>
        <Route index element={<Home />}/>
        <Route path="about" element={ <About/> } />
        <Route path="contact" element={ <Contact/> } />
        <Route path="makuni" element={ <Makuni/> } />
        </Route>
       
      </Routes>
    </div>
  )
}

export default App